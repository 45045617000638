import {
  Box,
  TableBody,
  TableCell,
  TableRow,
  TableHeader,
  Text,
} from "grommet";
import dynamic from "next/dynamic";
import { useInView } from "react-intersection-observer";
import { Fragment, memo } from "react";
import Watchlist from "@organisms/watchlist/button";
import { useCoin } from "@organisms/coin/provider";
import { MobileName, Name } from "./name";
import Icon from "./icon";
import CellLink from "./cellLink";
import Container from "./container";
import Header, { IconHeader } from "./table/header";

const Vote = dynamic(() => import("./vote"));
const ValueInUsd = dynamic(() => import("./valueInUsd"));
const SumRow = dynamic(() => import("./sumRow"));

function Row({ gaCategory, slug, rank, firstIndex, index, navigate, airdrop }) {
  const cellLinkProps = { slug, navigate, gaCategory, index };

  return (
    <Fragment>
      <TableCell
        size={airdrop ? "36px" : "26px"}
        key="watchlist"
        align="left"
        pad={{ left: "xsmall", right: "xsmall" }}
      >
        <Box align="center">
          {rank && (
            <Text size="xsmall" color="dark-4">
              {firstIndex + index + 1}
            </Text>
          )}
          <Watchlist rank={rank} slug={slug} short />
        </Box>
        <CellLink {...cellLinkProps} first />
      </TableCell>
      <TableCell key="name" align="left" direction="row" gap="small">
        <Icon slug={slug} size="42px" flex={false} />
        <MobileName slug={slug} />
        <CellLink {...cellLinkProps} />
      </TableCell>
      {airdrop && (
        <TableCell size="80px" key="valueInUsd" align="left">
          <ValueInUsd slug={slug} />
          <CellLink {...cellLinkProps} />
        </TableCell>
      )}
      {!airdrop && (
        <TableCell size="107px" key="votes" align="left">
          <Vote slug={slug} />
          <CellLink {...cellLinkProps} />
        </TableCell>
      )}
    </Fragment>
  );
}

function PlaceholderRow({ slug, navigate }) {
  return (
    <Fragment>
      <TableCell key="name" align="left" colSpan="4" pad={{ left: "107px" }}>
        <Name slug={slug} />
        <CellLink slug={slug} navigate={navigate} />
      </TableCell>
    </Fragment>
  );
}

function RowContainer({ gaCategory, index, slug, initialVisible, ...rest }) {
  const exist = useCoin(slug, (state) => Boolean(state));
  const skip = initialVisible === -1 || index < initialVisible;

  const { ref, inView } = useInView({
    rootMargin: "100px 0px",
    triggerOnce: true,
    skip,
  });

  return (
    <TableRow style={{ height: "72px" }} ref={ref} key={slug} round>
      {exist && (inView || skip) ? (
        <Row gaCategory={gaCategory} slug={slug} index={index} {...rest} />
      ) : (
        <PlaceholderRow slug={slug} {...rest} />
      )}
    </TableRow>
  );
}

const RowContainerMemo = memo(RowContainer);

export default function Mobile({
  slugs,
  gaCategory,
  promoted = false,
  airdrop = false,
  rank = true,
  firstIndex = 0,
  initialVisible = -1,
  navigate,
  ...rest
}) {
  return (
    <Container hasHeader elevation="xlarge" promoted={promoted} {...rest}>
      {promoted && (
        <TableHeader>
          <TableRow>
            <IconHeader
              id="watchlist"
              promoted={promoted}
              label={promoted ? "Promoted Coins" : ""}
              direction="row"
            />
            <Header key="name" />
            <Header key="votes" label={promoted ? "Votes" : ""} size="16px" />
          </TableRow>
        </TableHeader>
      )}
      <TableBody>
        {slugs.map((slug, index) => (
          <RowContainerMemo
            rank={rank}
            key={slug}
            slug={slug}
            firstIndex={firstIndex}
            index={index}
            navigate={navigate}
            initialVisible={initialVisible}
            gaCategory={gaCategory}
            airdrop={airdrop}
          />
        ))}
        {airdrop && (
          <SumRow initialVisible={initialVisible} index={slugs.length} />
        )}
      </TableBody>
    </Container>
  );
}
